@import url("https://fonts.googleapis.com/css?family=Poppins");
input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  font-family: "Poppins";
  border-radius: 4px;
  box-sizing: border-box;
}
input[type="date"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  font-family: "Poppins";
  border-radius: 4px;
  box-sizing: border-box;
}
input[type="submit"] {
  width: 100%;
  background-color: #0c5ca8;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input[type="button"] {
  width: 100%;
  background-color: #40453e;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins";
}
input[type="submit"]:hover {
  background-color: #0c5ca8;
}